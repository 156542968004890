import React from "react";
import cx from "classnames";
import { Reveal } from "src/components/reveal";
import { BlockCopy } from "src/components/block-copy";

import styles from "./post-module.module.css";

interface PostModuleProps {
  module: {
    post: {
      content: {
        main: {
          body: [];
        };
      };
    };
  };
}

export const PostModule = ({ module }: PostModuleProps) => {
  const { post } = module;
  return (
    <section className={cx(styles.postModule)}>
      {post && (
        <BlockCopy className={styles.body} copy={post.content.main.body} />
      )}
    </section>
  );
};
