import React from "react";
import cx from "classnames";
import { ConditionalWrapper } from "src/components/conditional-wrapper";
import { Link } from "src/components/link";
import { Image } from "src/components/image";
import { getInternalLinkPath } from "src/utils/getInternalLinkPath";
import { use100vh } from "react-div-100vh";

import styles from "./image-module.module.css";

interface ImageModuleProps {
  module: {
    image: {
      asset: {
        _id: string;
      };
    };
    link?: any;
  };
}

export const ImageModule = ({ module }: ImageModuleProps) => {
  const { image, link } = module;

  const height = use100vh();
  const minHeight = height && height - 86;

  return (
    <section style={{ minHeight }} className={cx(styles.imageModule)}>
      <ConditionalWrapper
        condition={link.link}
        wrapper={(children) => (
          <Link type="internalLink" link={{ internalLink: link }}>
            {children}
          </Link>
        )}
      >
        {image && <Image className={styles.image} imageId={image.asset._id} />}
      </ConditionalWrapper>
    </section>
  );
};
