import React, { useEffect, useState } from "react";
import { RevealOnMount } from "src/components/reveal-on-mount";
import { SEO } from "src/components/SEO";

import { renderModules } from "src/utils/renderModules";

export interface LandingPageProps {
  pageContext: {
    main: {
      slug: {
        current: string;
      };
      title: string;
      hero: any;
      modules: [];
    };
    meta: {};
  };
  location: any;
  path: string;
}

const LandingPage = ({ location, pageContext }: LandingPageProps) => {
  const { main, meta } = pageContext;

  return (
    <RevealOnMount location={location}>
      <SEO
        metaInfo={meta}
        pagePath={location?.pathname}
        slug={pageContext.main.slug.current}
      />
      {renderModules(main.modules)}
    </RevealOnMount>
  );
};

export default LandingPage;
