import React, { useState } from "react";
import { Link } from "src/components/link";
import { ErrorHandling } from "src/utils/error";
import { subscribe } from "klaviyo-subscribe";
import { use100vh } from "react-div-100vh";

import cx from "classnames";
import styles from "./newsleter-module.module.css";

interface NewsletterModuleProps {
  module: {
    klaviyoListId?: string;
  };
}

export const NewsletterModule = ({ module }: NewsletterModuleProps) => {
  const [isPending, setIsPending] = useState(false);
  const [success, setSuccess] = useState(false);
  const [signupError, setSignupError] = useState("");
  const [hasAceptedTerms, setHasAcceptedTerms] = useState(false);

  const { klaviyoListId } = module;

  const height = use100vh();
  const minHeight = height && height - 86;

  const onSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const form = e.currentTarget as HTMLFormElement;
    setIsPending(true);

    try {
      const email = e.target?.email?.value;

      const result = await subscribe(klaviyoListId, email);

      form.reset();

      if (result.errors.length > 0) {
        setSignupError("Something went wrong. Please try again later");
      } else {
        setSuccess(true);
        window?.fbq && window.fbq("trackCustom", "NewsletterSignup");
      }
    } catch (error) {
      console.error(error);
      setSignupError("Something went wrong. Please try again later");
    }

    setIsPending(false);
  };

  return (
    <section style={{ minHeight }} className={cx(styles.newsletterModule)}>
      <form onSubmit={onSubmit} className={cx(styles.newsletterForm)}>
        {isPending && <span>Loading</span>}
        {signupError && <ErrorHandling error={signupError} />}

        <div className="pya pb1">
          <label>
            Email newsletter signup
            <input
              name="email"
              type="text"
              required={true}
              className="accounts__input s16 mb0 x px1 py1"
              placeholder="Email Address"
            />
          </label>
        </div>
        <div className="f aic account__form-check-wrapper mt1">
          <div className="account__form-check">
            <input
              type="checkbox"
              id="newsletter"
              name="newsletter"
              className="account__form-checkbox-input"
              onChange={(e) => {
                setHasAcceptedTerms(e.target.checked);
              }}
            />
            <span className="account__form-checkbox-mark" />
          </div>
          <label htmlFor="newsletter">
            I consent to the processing of my personal data according to the{" "}
            <Link
              type="internalLink"
              to="/privacy-policy"
              className="underline"
            >
              privacy policy.
            </Link>
          </label>
        </div>
        <button
          type="submit"
          className="cta cta--black mt1"
          disabled={!hasAceptedTerms || isPending}
        >
          <span>{isPending ? "Loading" : "Submit"}</span>
        </button>
        {success && (
          <p className={cx(styles.message)}>
            Thank you!
            <br />
            Please check your email to confirm your subscription.
          </p>
        )}
      </form>
    </section>
  );
};
