import React, { useRef, useEffect } from "react";
import cx from "classnames";
import { ConditionalWrapper } from "src/components/conditional-wrapper";
import { Link } from "src/components/link";
import { Image } from "src/components/image";
import { use100vh } from "react-div-100vh";
import { useStoreContext } from "src/context/siteContext";

import styles from "./hero.module.css";

interface Media {
  _key: string;
  asset?: {
    _id: string;
  };
  image?: {
    asset: {
      _id: string;
      url: string;
    };
    alt?: string;
  };
  mp4?: {
    asset: {
      url: string;
    };
  };
  showInlineOnMobile?: boolean;
}

interface HeroProps {
  hero: {
    media?: Media;
    mobileMedia?: Media;
    mediaLayout?: "contain" | "cover";
    link?: any;
  };
  solo: boolean;
}

const HeroMedia = ({ hero }: HeroProps) => {
  const { media, mobileMedia, mediaLayout } = hero;

  if (media?.mp4?.asset?.url) {
    return (
      <>
        <div className={styles.heroVideo}>
          <div className={styles.videoContainer}>
            <video
              loop
              autoPlay
              muted
              playsInline
              controls={false}
              poster={media.image?.asset?.url}
              preload="auto"
              style={{ objectFit: mediaLayout }}
            >
              <source src={media.mp4.asset.url} type="video/mp4" />
              {media.image?.asset._id && (
                <Image
                  className={styles.heroImage}
                  imageId={media.image.asset._id}
                  width={2400}
                  style={{ height: "100%" }}
                  objectFit={mediaLayout}
                  objectPosition="50% 50%"
                  loading="eager"
                  alt={media.image.alt ? media.image.alt : "District Vision"}
                />
              )}
            </video>
          </div>
        </div>
        {mobileMedia && mobileMedia.mp4?.asset?.url && (
          <div className={styles.heroMobileVideo}>
            <div className={styles.videoContainer}>
              <video
                loop
                autoPlay
                muted
                playsInline
                controls={false}
                poster={mobileMedia.image?.asset?.url}
                preload="auto"
                style={{ objectFit: mediaLayout }}
              >
                <source src={mobileMedia.mp4.asset.url} type="video/mp4" />
                {mobileMedia.image?.asset._id && (
                  <Image
                    className={styles.heroImage}
                    imageId={mobileMedia.image.asset._id}
                    width={2400}
                    style={{ height: "100%" }}
                    objectFit={mediaLayout}
                    objectPosition="50% 50%"
                    loading="eager"
                    alt={
                      mobileMedia.image.alt
                        ? mobileMedia.image.alt
                        : "District Vision"
                    }
                  />
                )}
              </video>
            </div>
          </div>
        )}
      </>
    );
  } else if (media?.image?.asset?._id) {
    return (
      <>
        <Image
          className={styles.heroImage}
          imageId={media.image.asset._id}
          width={2400}
          style={{ height: "100%" }}
          objectFit={mediaLayout}
          objectPosition="50% 50%"
          loading="eager"
          alt={media.image.alt ? media.image.alt : "District Vision"}
        />
        {mobileMedia && mobileMedia?.image?.asset?._id && (
          <Image
            className={styles.heroMobileImage}
            imageId={mobileMedia.image.asset._id}
            width={1200}
            style={{ height: "100%" }}
            objectPosition="50% 50%"
            loading="eager"
            alt={
              mobileMedia.image.alt ? mobileMedia.image.alt : "District Vision"
            }
          />
        )}
      </>
    );
  } else {
    return null;
  }
};

export const Hero = ({ hero, solo }: HeroProps) => {
  const { link, mobileMedia } = hero;
  const { noticeBarVisible, noticeBarDismissed } = useStoreContext();

  const height = use100vh();
  const minHeight = height && height - 86;

  return (
    <div
      className={cx(
        styles.hero,
        mobileMedia ? styles.hasMobileMedia : "",
        solo ? styles.solo : ""
      )}
      style={{ minHeight }}
    >
      <ConditionalWrapper
        condition={link.link}
        wrapper={(children) => (
          <Link
            className={styles.link}
            type="internalLink"
            link={{ internalLink: link }}
          >
            {children}
          </Link>
        )}
      >
        <HeroMedia hero={hero} />
      </ConditionalWrapper>
    </div>
  );
};
