import React from "react";
import cx from "classnames";
import Countdown from "react-countdown";
import { use100vh } from "react-div-100vh";

import styles from "./countdown-module.module.css";

interface CountdownModuleProps {
  module: {
    title?: string;
    launchDate: string;
  };
}

export const CountdownModule = ({ module }: CountdownModuleProps) => {
  const { title, launchDate } = module;

  const height = use100vh();
  const minHeight = height && height - 86;

  return (
    <section style={{ minHeight }} className={cx(styles.countdownModule)}>
      <h2>{title}</h2>
      <Countdown date={launchDate} />
    </section>
  );
};
