import React from "react";
import { Image } from "src/components/image";
import { Link } from "src/components/link";

import { use100vh } from "react-div-100vh";

import "./hero-cta.css";
import styles from "./hero-cta.css";

export const HeroCTA = ({ module }) => {
  const { links, media } = module;

  console.log("module", module);

  return (
    <div className="full-screen hero-cta">
      {media && (
        <Image
          className={styles.heroImage}
          imageId={media.image.asset._id}
          width={2400}
          style={{ height: "100%" }}
          objectFit="contain"
          objectPosition="50% 50%"
          loading="eager"
          alt={media.image.alt ? media.image.alt : "District Vision"}
        />
      )}
      {links && (
        <div className="hero-cta__links">
          {links.map((link) => (
            <Link type="internalLink" link={{ internalLink: link }}>
              <span className="underline-on-hover">
                {link.title}{" "}
                <svg
                  width="4"
                  height="7"
                  viewBox="0 0 4 7"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M1 1L3 3.5L1 6" stroke="black" />
                </svg>
              </span>
            </Link>
          ))}
        </div>
      )}
    </div>
  );
};
