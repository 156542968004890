import React from "react";
import cx from "classnames";
import { BlockCopy } from "src/components/block-copy";
import { use100vh } from "react-div-100vh";

import styles from "./copy-module.module.css";

interface CopyModuleProps {
  module: {
    copy?: [];
  };
}

export const CopyModule = ({ module }: CopyModuleProps) => {
  const { copy } = module;

  const height = use100vh();
  const minHeight = height && height - 86;

  return (
    <section style={{ minHeight }} className={cx(styles.copyModule)}>
      {copy && <BlockCopy copy={copy} />}
    </section>
  );
};
