import React, { Fragment } from "react";
import { Hero } from "src/components/hero";
import { CopyModule } from "src/components/landing-page/copy-module";
import { PostModule } from "src/components/landing-page/post-module";
import { ImageModule } from "src/components/landing-page/image-module";
import { CountdownModule } from "src/components/landing-page/countdown-module";
import { NewsletterModule } from "src/components/landing-page/newsletter-module";
import { HeroCTA } from "src/components/landing-page/hero-cta";

const Modules = ({
  type,
  data,
  index,
  modulesLength,
}: {
  type: string;
  data: any;
  index: number;
  modulesLength: number;
}) => {
  // if module is hidden return empty fragment
  if (data.hide) {
    return <Fragment />;
  }

  const isProduction = process.env.NODE_ENV !== "development";

  switch (type) {
    case "hero":
      return <Hero hero={data} solo={index + 1 === modulesLength} />;
    case "copyModule":
      return <CopyModule module={data} />;
    case "postModule":
      return <PostModule module={data} />;
    case "imageModule":
      return <ImageModule module={data} />;
    case "countdownModule":
      return <CountdownModule module={data} />;
    case "newsletterModule":
      return <NewsletterModule module={data} />;
    case "heroCTA":
      return <HeroCTA module={data} />;
    default:
      return isProduction ? <Fragment /> : <p className="m1 p1 ac">{type}</p>;
  }
};

export const renderModules = (modules: []) => {
  if (modules) {
    return modules.map((module: { _key: string; _type: string }, index) => {
      return (
        <Modules
          key={module._key}
          type={module._type}
          data={module}
          index={index}
          modulesLength={modules.length}
        />
      );
    });
  }
};
